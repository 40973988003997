.tag-component {
    top: -17px;
    font-family: 'Lato';
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 2px;
    line-height: 17px;
    text-align: center;
    padding: 9px 22px;
    border-radius: 20.5px;
    position: absolute;
  }